
import { defineComponent } from '@nuxtjs/composition-api';
import { getEsFooterContent, getEsNavBarAccountContent, getEsNavBarGlobalContent } from '@energysage/es-vue-base';
import { analyticsMixin } from '@/mixins/analytics';

export default defineComponent({
    name: 'DefaultLayout',
    mixins: [analyticsMixin],
    computed: {
        footerContent() {
            const footerContent = getEsFooterContent();

            // update home link to root-relative url
            footerContent.home.link = '/';

            return footerContent;
        },
        navAccountContent() {
            return getEsNavBarAccountContent();
        },
        navGlobalContent() {
            const navGlobalContent = getEsNavBarGlobalContent();

            // update home link to root-relative url
            navGlobalContent.home.link = '/';

            return navGlobalContent;
        },
    },
});
