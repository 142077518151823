import Vue from 'vue';
import type { Route } from 'vue-router';
import type { QueryParams } from '@/types/urls';

// Expire in 90 days (per Marketing and Product teams)
export const COOKIE_AGE = 90 * 24 * 60 * 60 * 1000;

// Hubspot
export const HUBSPOT_QUEUE_KEY = '_hsq';

// Referral Tracking
export const RC_COOKIE_NAME = 'rc';
export const RC_COOKIE_EXTERNAL_NAME = 'extrc';
export const RC_COOKIE_QUERY_PARAM_NAME = 'rc';

// Source Attribution Tracking
export const SOURCE_ATTRIBUTION_COOKIE_NAME = 'sa';
const SA_SINGLE_PARAM_NAMES = ['gclid', 'fbclid', 'msclkid', 'cjevent'];
const SA_UTM_PARAM_NAMES = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_terms', 'utm_content'];
const SA_ALL_PARAM_NAMES = [...SA_SINGLE_PARAM_NAMES, ...SA_UTM_PARAM_NAMES];

// VWO Full-stack handoff
const VWO_FS_HANDOFF_COOKIE_NAME = 'vwo_fs_handoff';
const VWO_FS_UUID_COOKIE_NAME = '_es_uuid';
const GTM_FS_EVENT_NAME = 'VWO_FS_Test';

/**
 * Tracks source of user registrations, e.g. from paid ad or email campaign.
 * Must be kept in sync with `ALL_PARAM_NAMES` below and fields on `es.accounts.models.SourceAttribution`.
 * Params defined here will be saved to the cookie, but only saved to the database if they're defined in the model.
 * Define a new single param ala `gclid` as a `@property` and in `SINGLE_PARAM_NAMES` below.
 * Defining a new set of params ala `utm_*` will require duplicated logic or refactoring.
 */
type SourceAttribution = {
    gclid?: string; // Google Click ID
    fbclid?: string; // Facebook Click ID
    msclkid?: string; // Microsoft/Bing Click ID
    utm_source?: string; // The site that sent the traffic, e.g. Google (required for other utm_* properties)
    utm_medium?: string; // The type of link used, such as cost per click or email
    utm_campaign?: string; // The specific product promotion or strategic campaign
    utm_term?: string; // Search terms
    utm_content?: string; // What was clicked to bring the user to the site, e.g. banner ad or link
    cjevent?: string; // Commission Junction event token
    last_attribution?: string; // When cookie was last updated (in ISO format, e.g. '2019-04-03T16:40:19.000Z')
};

// Extending window per https://stackoverflow.com/a/56458070
declare global {
    interface Window {
        [HUBSPOT_QUEUE_KEY]: any;
        dataLayer: any;
    }
}

type CookieOptions = {
    maxAge: number;
    domain?: string;
};

/**
 * Given a Route.query value, return only the first value if a list
 */
const stringFromQuery = (queryVal: string | (string | null)[]) => {
    let val;
    if (Array.isArray(queryVal)) {
        // eslint-disable-next-line prefer-destructuring
        val = queryVal[0];
    } else {
        val = queryVal;
    }
    return val === null ? undefined : val;
};

/**
 * Given a Route.query Dictionary, choose the first index if the value is an array
 */
const singleValueQuerystring = (query: Route['query']) => {
    const singleValueQuery = Object.keys(query).reduce((acc, curr) => {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        acc[curr] = stringFromQuery(query[curr]);
        return acc;
    }, {});
    return singleValueQuery;
};

export const getAttribution = (queryParams: Route['query']): SourceAttribution | null => {
    const singleQueryParams: SourceAttribution = singleValueQuerystring(queryParams);
    const attribution: QueryParams = { last_attribution: new Date().toISOString() };
    let numParams = 0;

    // UTM attribution requires `utm_source`
    const paramNames = singleQueryParams.utm_source ? SA_ALL_PARAM_NAMES : SA_SINGLE_PARAM_NAMES;
    paramNames.forEach((paramName) => {
        const paramValue = singleQueryParams[paramName as keyof SourceAttribution];
        if (paramValue) {
            attribution[paramName] = paramValue;
            numParams += 1;
        }
    });
    return numParams ? attribution : null;
};

/**
 * Returns a new set of attributions after merging with an existing set.
 * Assuming arguments are from `getAttributions`, so we don't need to handle invalid param names.
 */
export const mergeAttributions = (
    oldAttribution: SourceAttribution, // The existing set of attributions (most likely from an existing cookie)
    newAttribution: SourceAttribution, // The latest set of attributions (most likely from a query string)
): SourceAttribution => {
    const attribution: SourceAttribution = Object.keys(oldAttribution).reduce(
        (acc: SourceAttribution, key: string) => {
            // UTM values of newAttribution override all UTM values of oldAttribution
            if (newAttribution.utm_source && SA_UTM_PARAM_NAMES.includes(key)) {
                return acc;
            }
            // @ts-ignore
            // eslint-disable-next-line no-param-reassign
            acc[key] = oldAttribution[key];
            return acc;
        },
        {},
    );
    return { ...attribution, ...newAttribution };
};

export const cleanRcValue = (rcValue: string | undefined) => {
    if (!rcValue) {
        return undefined;
    }
    const cleanRc = rcValue
        .toString()
        .replace(/\/$/, '')
        .replace(/(\/|\?|=)/g, '-');
    return cleanRc;
};

export const getCurrentRcValue = (
    rcQuery: string | (string | null)[],
    rcCookie: string | undefined,
    rcCookieExt: string | undefined,
) => cleanRcValue(stringFromQuery(rcQuery) || rcCookie || rcCookieExt || undefined);

export const trackHubspotPageView = (route: string) => {
    window[HUBSPOT_QUEUE_KEY] = window[HUBSPOT_QUEUE_KEY] || [];
    // @ts-ignore
    window[HUBSPOT_QUEUE_KEY].push(['setPath', route]);
    window[HUBSPOT_QUEUE_KEY].push(['trackPageView']);
};

export const trackRcValue = (rcValue: string) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        ReferralCode: rcValue,
    });
    window.dataLayer.push({
        event: 'rc-push',
    });
};

export const trackSaValue = (saValue: SourceAttribution) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        ...saValue,
    });
    window.dataLayer.push({
        event: 'sa-push',
    });
};

type VwoHandoffData = {
    campaign_id: string;
    campaign_key: string;
    variation_id: string;
    variation_name: string;
};

export const analyticsMixin = Vue.extend({
    watch: {
        $route() {
            this.setRcValue();
            this.setSaValue();
            trackHubspotPageView(this.$route.path);
        },
    },
    mounted() {
        this.setRcValue();
        this.setSaValue();
        trackHubspotPageView(this.$route.path);
        this.vwoFullStackToGtmHandoff();
    },
    methods: {
        setRcValue() {
            const rcQuery = this.$route.query[RC_COOKIE_QUERY_PARAM_NAME];
            const rcCookie = this.$cookies.get(RC_COOKIE_NAME);
            const rcCookieExt = this.$cookies.get(RC_COOKIE_EXTERNAL_NAME);
            const rc = getCurrentRcValue(rcQuery, rcCookie, rcCookieExt);
            if (rc && rc !== rcCookie) {
                const options: CookieOptions = {
                    maxAge: COOKIE_AGE,
                    domain: this.$config.COOKIE_DOMAIN,
                };
                this.$cookies.set(RC_COOKIE_NAME, rc, options);
            }
            if (rc) {
                trackRcValue(rc);
            }
        },
        setSaValue() {
            const newAttribution = getAttribution(this.$route.query);

            if (!newAttribution) {
                return;
            }

            const cookieData = this.$cookies.get(SOURCE_ATTRIBUTION_COOKIE_NAME) || {};
            const attribution = mergeAttributions(cookieData, newAttribution);
            const options: CookieOptions = {
                maxAge: COOKIE_AGE,
                domain: this.$config.COOKIE_DOMAIN,
            };
            this.$cookies.set(SOURCE_ATTRIBUTION_COOKIE_NAME, attribution, options);
            if (attribution) {
                trackSaValue(attribution);
            }
        },
        /**
         * Read VWO FS cookie, and put that information into the dataLayer for GTM to pickup
         */
        vwoFullStackToGtmHandoff() {
            const vwoFsHandoff = this.$cookies.get(VWO_FS_HANDOFF_COOKIE_NAME);
            // Get full-stack cookie data set by the server
            if (!vwoFsHandoff) {
                return;
            }

            // Read cookie data into the data-layer for GTM
            const testParams = new URLSearchParams(decodeURIComponent(vwoFsHandoff));
            const vwoFsHandoffData = ['campaign_id', 'campaign_key', 'variation_id', 'variation_name'].reduce(
                (acc, paramName) => {
                    if (testParams.has(paramName)) {
                        // @ts-ignore
                        // eslint-disable-next-line no-param-reassign
                        acc[paramName] = testParams.get(paramName);
                        if (paramName.endsWith('_id') && acc[paramName as keyof VwoHandoffData]) {
                            // @ts-ignore
                            // eslint-disable-next-line no-param-reassign
                            acc[paramName] = Number(acc[paramName]);
                        }
                    }
                    return acc;
                },
                {} as Partial<VwoHandoffData>,
            );
            const uuid = this.$cookies.get(VWO_FS_UUID_COOKIE_NAME);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: GTM_FS_EVENT_NAME,
                uuid,
                ...vwoFsHandoffData,
            });
        },
    },
});
